/*
 Theme Name: Contemporary Tropical - Multi-style
 Version: 1
 Author: Alok Jain
 Last edited: Jaspreet Kaur - August 31, 2020
*/

a {
	color: #282828;
	text-decoration: underline;
	outline: none;
}

a:hover {
	text-decoration: none;
}

/* common styling */
blockquote {
	margin-left: 20px;
	border-left: 2px solid #878484;
	padding-left: 20px;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	border: 1px solid #878484;
	padding: 20px;
}

thead,
tr,
td,
th {
	border: 1px solid #878484;
	text-align: center;
	vertical-align: middle;
}

body {
	font-family: "Droid Sans", sans-serif;
	font-size: 1.3rem;
}
.container {
	width: 1120px;
	margin: 0 auto;
}
.container:after {
	visibility: hidden;
	display: block;
	content: "";
	clear: both;
	height: 0;
}
.article {
	float: left;
	width: 800px;
	padding-right: 10px;
}
aside {
	float: left;
	width: 298px;
	position: relative;
	left: -1px;
	padding-left: 10px;
}
input:focus,
textarea:focus {
	outline: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.liked-list-title,
.fplist-title,
.notice_title,
.contact-title,
.social-title,
.comment_title,
.testimonial_subtitle,
.agent_listings_header {
	font-family: "Droid Sans", sans-serif;
	font-size: 1.6rem;
}
.text,
input[type="text"] {
	font-size: 1.3rem;
	font-family: "Droid Sans", sans-serif;
	font-weight: 400;
	padding: 0 5px;
	box-sizing: border-box;
}
select {
	height: 35px;
	-webkit-appearance: none; /*Removes default chrome and safari style*/
	-moz-appearance: none; /*Removes default style Firefox*/
	background: url("/templates/template_35/images/dropdown-arrow.png") no-repeat;
	background-position: calc(100% - 6px) 12px;
	padding: 7px 22px 7px 7px;
}
select::-ms-expand {
	display: none;
}

#like_outline {
	display: none !important;
}
.banner-search input,
.banner-search select {
	background: none;
	padding: 7px;
	font-size: 1.4rem;
	margin-bottom: 20px;
}

.banner-search select {
	background: #282828
		url("/templates/template_35/images/white-dropdown-arrow.png") no-repeat;
	background-position: calc(100% - 12px) 10px;
	width: 170px;
	padding: 7px 22px 7px 7px;
	border-radius: 0;
}

.banner-search .the_search {
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
}

.home-page-list-group {
	display: none;
}
/* Forms */
input,
select,
textarea,
input[type="text"],
input[type="email"],
input[type="phone"],
input[type="tel"],
input[type="password"] {
	font-size: 1.4rem;
	padding: 7px;
}

select,
textarea,
input[type="text"],
input[type="email"],
input[type="phone"],
input[type="tel"],
input[type="password"] {
	width: 240px;
	box-sizing: border-box;
}

input[type="text"],
input[type="email"],
input[type="phone"],
input[type="tel"],
input[type="password"],
textarea {
	background-color: transparent;
}

input[type="file"] {
	border: none;
}

input.thename[type="text"] {
	width: 208px;
}
input[type="text"].thefirstname,
input[type="text"].thelastname {
	width: 206px;
	margin-left: 5px;
}

input[type="submit"],
input[type="button"],
button,
.detail_pagination a,
.detail_pagination-top a,
.save-search a {
	display: inline-block;
	font-size: 1.5rem;
	line-height: 24px;
	margin-top: 5px;
	padding: 5px 15px;
	text-transform: uppercase;
	cursor: pointer;
	text-decoration: none;
}
.searchform form li,
.cma_form_container form li,
.the_contact_form form li {
	width: 100%;
	overflow: hidden;
	margin-bottom: 8px;
}
.the_contact_form form li textarea {
	background: #fff;
	width: 100%;
	min-width: 100%;
	border: 1px solid #d0d0d0;
}
.searchform .searchprice input[type="text"] {
	display: block;
}
.searchform .searchprice input[type="text"]:first-of-type {
	margin-bottom: 10px;
}
.search-form-container .search_columns {
	width: 100%;
}
.search-form-container .search_columns ol {
	width: auto;
}
.search-form-container .search_columns .column_one {
	margin-right: 20px;
}
.searchitems {
	overflow: hidden;
	margin-bottom: 20px;
}
#detailform li b,
#commentform b,
.mtg_form li label,
#detailform li label,
#commentform label {
	width: 125px;
	display: block;
	float: left;
	line-height: 21px;
	margin-top: 10px;
	font-weight: 700;
}
#commentform .instructions {
	margin-bottom: 20px;
	margin-left: 0px;
	color: #555;
	text-transform: uppercase;
	font-size: 1.7rem;
}

#detailform li b,
.contactform b,
#detailform li laebl,
.contactform label {
	width: 100px !important;
	display: inline-block;
}
.contactform p {
	margin-bottom: 20px;
	line-height: 18px;
}
form .note,
form h2,
.mtg_title,
.testimonial_subtitle {
	margin-bottom: 15px;
	margin-top: 10px;
	text-transform: uppercase;
	font-size: 1.7rem;
}

.search_column .note {
	margin-top: 0px;
	margin-bottom: 20px;
	text-transform: none;
	font-size: 1.4rem;
}

.search_mlsid .note {
	margin-bottom: 5px;
	text-transform: none;
	font-size: 1.4rem;
	line-height: 18px;
}

.pagebody .field_msg {
	display: block;
	margin-bottom: 10px;
}

.pagebody .fb_login {
	display: block;
	text-decoration: none;
	font-size: 1.5rem;
}

#fb_login_form li {
	margin-bottom: 10px;
}

.pagebody .register_help h2 {
	color: #000;
}

.pagebody .register_fields label {
	width: 70px;
	display: inline-block;
}

.pagebody .register_fields input {
	width: 225px;
	margin-left: 0 !important;
}

header {
	width: 100%;
	min-width: 1120px;
}

header .menu-title {
	display: none;
}

header .agentheader {
	padding: 20px 20px 20px 0px;
	float: left;
	max-width: 580px;
	box-sizing: border-box;
	display: table;
}

.agentheader .agentimage {
	display: table-cell;
	float: left;
	vertical-align: top;
	margin-right: 35px;
}

.agentheader .brokerlogo {
	margin-right: 35px;
}

.agentheader ol {
	font-size: 1.6rem;
	display: table-cell;
	vertical-align: top;
}

.agentheader ol li {
	line-height: 22px;
	list-style: none;
}

.agentheader .agentname {
	font-weight: 700;
	font-size: 2.2rem;
	margin-bottom: 15px;
}

.agentheader .agent_designations {
	font-weight: 400;
	font-size: 1.6rem;
	word-break: break-all !important;
}

.agentheader .tagline {
	font-weight: 700;
	margin-bottom: 15px;
}

#main-header {
	padding: 5px 0px;
}

#pre-header {
	width: 100%;
	min-width: 1120px;
	overflow: hidden;
	height: 38px;
	line-height: 38px;
	font-size: 1.4rem;
	text-transform: uppercase;
}

#pre-header p {
	float: left;
}

#pre-header .social {
	float: right;
	margin: 0;
}

#pre-header .social .social-title,
#pre-header .social ul,
.social li {
	float: left;
	margin-left: 5px;
}

#pre-header .social a {
	position: relative;
}

.social a img {
	position: relative;
	top: 0;
}

.social a:hover img {
	position: relative;
	top: -38px;
}

#pre-header .social .social-title {
	line-height: 38px;
	padding: 0px;
}

.tsr_logo {
	width: 190px;
}

.header-right-section {
	width: 520px;
	float: right;
}

.menu {
	margin-top: 45px;
	float: right;
}

.menu ul {
	position: relative !important;
	padding: 30px 0 0;
	line-height: 22px;
	z-index: 5;
	display: none;
}

.menu ul li {
	display: block;
	float: left;
	margin: 0;
	padding: 0;
}
.menu ul li a {
	display: block;
	position: relative;
	padding: 10px 20px;
	font-size: 1.5rem;
	text-decoration: none;
	-webkit-transition: color 0.3s ease-in;
	-moz-transition: color 0.3s ease-in;
	-o-transition: color 0.3s ease-in;
	-ms-transition: color 0.3s ease-in;
	transition: color 0.3s ease-in;
}
.menu ul .nav-wrap > a {
	padding: 10px 30px 10px 20px;
}
.menu ul .nav-wrap > a::after {
	content: "";
	position: absolute;
	top: 17px;
	right: 10px;
	width: 7px;
	height: 7px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
}

.menu ul .nav-wrap {
	position: relative;
}
.menu ul ul {
	position: absolute !important;
	margin: 0;
	padding: 0;
	list-style: none;
	display: none;
}
.menu ul ul li {
	position: absolute;
	top: -9999px;
	/* Hiding them */
	height: 0px;
	display: block;
	margin: 0;
	padding: 0;
	/* Making them to expand their height with a transition, for a slide effect */
	-webkit-transition: height 0.2s ease-in;
	-moz-transition: height 0.2s ease-in;
	-o-transition: height 0.2s ease-in;
	-ms-transition: height 0.2s ease-in;
	transition: height 0.2s ease-in;
}
.menu ul ul li a {
	/* Layout */
	padding: 4px 20px;
	width: 225px;
	display: block;
	position: relative;
	/* Text */
	text-decoration: none;
	font-size: 1.4rem;
	/* Background & effects */
	-webkit-transition: color 0.3s ease-in, background 0.3s ease-in;
	-moz-transition: color 0.3s ease-in, background 0.3s ease-in;
	-o-transition: color 0.3s ease-in, background 0.3s ease-in;
	-ms-transition: color 0.3s ease-in, background 0.3s ease-in;
	transition: color 0.3s ease-in, background 0.3s ease-in;
}
.menu ul .nav-wrap:hover ul {
	top: 42px;
}
.menu ul ul .nav-wrap a::after {
	width: 6px;
	height: 6px;
	border-bottom: 0;
	top: 12px;
}
/* end menu */
.nav-wrap:hover > ul > li {
	height: 30px;
	position: relative;
	top: auto;
}

#banner {
	width: 100%;
	min-width: 1120px;
	position: relative;
	z-index: 0;
	height: 550px;
}
#banner .banner-search {
	width: 100%;
	margin-bottom: 5px;
}
#banner .banner-search #sidesearch {
	margin: 20px 0px;
	position: relative;
}
#banner .banner-search #sidesearch:after {
	content: "";
	display: table;
	clear: both;
}
#banner .banner-search .search-title {
	font-size: 1.8rem;
	line-height: 20px;
	margin-bottom: 20px;
	padding: 10px 20px;
	position: absolute;
	text-transform: uppercase;
	top: -60px;
	z-index: 10;
}
#banner .banner-search ol {
	color: #fff;
}
#banner .banner-search li {
	display: inline-block;
	margin-right: 20px;
}
#banner .banner-search li.ss_price_to,
#banner .banner-search li.ss_price_from {
	width: 103.5px;
}
#banner .banner-search li input[type="text"] {
	width: 103px;
	border: 1px solid #fff;
	height: 35px;
	color: #fff;
}
#banner .banner-search b,
#banner .banner-search label {
	margin-right: 10px;
	text-transform: uppercase;
	font-size: 1.4rem;
	display: block;
}
#banner .action-strip {
	text-align: center;
	position: relative;
	z-index: 1;
	padding-top: 450px;
}
#banner .action-strip p {
	font-size: 1.8rem;
	display: inline-block;
	padding: 20px;
}
#banner .action-strip a {
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
	padding: 10px 15px;
	display: inline-block;
	font-size: 1.4rem;
	margin-right: 20px;
	text-decoration: none;
}
#content {
	padding-top: 20px;
	margin-bottom: 20px;
}
#content .sign-up-block a:hover span {
	display: none;
}
#content .sign-up-block a:hover span.service-desc {
	display: block;
}
#content li.no-margin {
	margin-right: 0px;
}
#tabbed-links {
	margin-bottom: 25px;
	overflow: hidden;
	padding-left: 0;
}
#tabbed-links li {
	width: 25%;
	float: left;
	text-align: center;
	text-transform: uppercase;
	line-height: 40px;
	font-size: 1.5rem;
	box-sizing: border-box;
	list-style: none;
}
#tabbed-links li:last-child {
	border: none;
}
#tabbed-links a {
	display: block;
	text-decoration: none;
}
#services {
	overflow: hidden;
	padding-left: 0;
}
#services li {
	float: left;
	margin-right: 38px;
	margin-bottom: 28px;
	position: relative;
	list-style: none;
}
#services li span {
	position: absolute;
	bottom: 20px;
	left: 0px;
	font-size: 1.5rem;
	display: inline-block;
	padding: 5px 10px;
}
#services li span.service-desc {
	display: none;
}
#services li a:hover span {
	display: none;
}
#services li a .tile-desc {
	display: none;
	position: absolute;
}
#services li a:hover .tile-desc {
	display: table !important;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	text-align: center;
	line-height: 22px;
	height: 205px;
	width: 100%;
}
#services li a .tile-desc .desc {
	padding: 0px 10px 0px 10px;
	font-size: 1.5rem;
	font-weight: 700;
	overflow-wrap: break-word;
	vertical-align: middle;
	display: table-cell;
}
#services li a .tile-desc {
	background: rgba(0, 0, 0, 0.7);
	color: #fff;
}
.ui-tabs {
	position: relative;
	/* position: relative prevents IE scroll bug (element with position: relative inside container with overflow: auto appear as "fixed") */
	padding: 0.2em;
}
.ui-tabs .ui-tabs-nav {
	margin: 0;
	padding: 0.2em 0.2em 0;
}
.ui-tabs .ui-tabs-nav li {
	list-style: none;
	float: left;
	position: relative;
	top: 0;
	margin: 1px 0.5em 0 0;
	border-bottom-width: 0;
	padding: 0;
	white-space: nowrap;
}
.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
	float: left;
	text-decoration: none;
	font-size: 1.5rem;
	text-transform: uppercase;
	padding: 10px;
}
.ui-tabs .ui-tabs-nav li.ui-tabs-active {
	margin-bottom: -1px;
	padding-bottom: 1px;
}
.ui-tabs .ui-tabs-panel {
	display: block;
	box-sizing: border-box;
	clear: both;
	font-size: 1.3rem;
	line-height: 22px;
	margin-bottom: 25px;
	padding: 10px;
	width: 100%;
}

.ui-tabs #tabs-2.ui-tabs-panel {
	padding: 10px 17px;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor,
.ui-tabs .ui-tabs-nav li.ui-state-disabled .ui-tabs-anchor,
.ui-tabs .ui-tabs-nav li.ui-tabs-loading .ui-tabs-anchor {
	cursor: text;
	outline: none;
}
.ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor {
	cursor: pointer;
	outline: none;
}
.detail_mini .ui-tabs .ui-tabs-panel {
	padding: 25px 10px 10px;
}
#tabbed-section {
	margin-bottom: 50px;
}
/* Full-view-carousel */
.fplist-container {
	position: relative;
	text-align: center;
}
.fplist-container h1 {
	font-size: 1.8rem;
	line-height: 20px;
	text-transform: uppercase;
	margin-bottom: 15px;
}
.fplist-container .prev-link {
	background: url(/templates/template_35/images/arrow-left.png) no-repeat;
	width: 21px;
	height: 23px;
	position: absolute;
	top: 200px;
	margin-top: -12px;
	left: -50px;
}
.fplist-container .next-link {
	background: url(/templates/template_35/images/arrow-right.png) no-repeat;
	width: 21px;
	height: 23px;
	position: absolute;
	top: 200px;
	margin-top: -12px;
	right: -50px;
}
.fplist-container .propholder {
	width: 1093px;
	float: left;
	margin-bottom: 0px;
}
.all-agents-btn,
.fpmorelink a {
	cursor: pointer;
	font-weight: bold;
	font-size: 1.5rem;
	line-height: 24px;
	text-transform: uppercase;
	padding: 5px 15px;
	display: inline-block;
	margin: 20px 0px;
	text-decoration: none;
}
.propholder {
	margin-bottom: 20px;
	overflow: hidden;
	-webkit-transition: background 2s;
	/* Safari 3.1 to 6.0 */
	transition: background 0.5s;
}
.propholder .sd_addressbar,
.propholder .column-title {
	margin-bottom: 10px;
	font-size: 1.5rem;
	line-height: 24px;
	text-transform: uppercase;
	padding-bottom: 0;
}
.propholder > .sd_addressbar {
	padding: 10px;
	position: relative;
	text-align: left;
}

.alt_address a {
	text-decoration: none;
	line-height: 20px;
}
.fplist .alt_property_container {
	max-width: 345px;
}
.fplist .alt_propimage img,
.alt_propimage .fplist img img {
	width: 346px;
}
.fplist .alt_virtualtour a,
.fplist .alt_pricedrop a,
.fplist .alt_green a,
.fplist .alt_adult a,
.fplist .alt_foreclosure a,
.fplist .alt_shortsale a {
	float: right;
}
.fplist .right_side {
	line-height: 15px;
	font-size: 1.2rem;
}
.fplist .alt_title a.menu_log {
	bottom: 0px;
	position: absolute;
	left: 44px;
	font-size: 1.3rem;
	color: #fff;
}
.fplist .propholder .alt_title .like_off,
.fplist .propholder .alt_title .like_on {
	top: 51%;
	left: 0;
}
.fplist .alt_property_container .like_on,
.fplist .alt_property_container .like_off {
	text-indent: -9999px;
	position: absolute;
	bottom: 0px;
	left: 30px;
}

.fplist.agentlist {
	overflow: hidden;
}
.fplist .more-info-link {
	bottom: 9px;
}
.fplist .alt_price {
	bottom: 11px;
}
.agent_slide img {
	margin-top: 5px;
}
.agents-list-block {
	position: relative;
	text-align: center;
}
.agents-list-block .prev-link {
	background: url(/templates/template_35/images/arrow-left.png) no-repeat;
	width: 21px;
	height: 23px;
	position: absolute;
	top: 200px;
	margin-top: -12px;
	left: -50px;
}
.agents-list-block .next-link {
	background: url(/templates/template_35/images/arrow-right.png) no-repeat;
	width: 21px;
	height: 23px;
	position: absolute;
	top: 200px;
	margin-top: -12px;
	right: -50px;
}

.agent_slide {
	float: left;
	width: 200px;
	height: 400px;
	border: 1px solid #999;
	background: #fff;
	margin: 10px 7px;
	text-align: center;
	word-wrap: break-word;
}

.agent_slide > a {
	display: block;
	height: 250px;
}

.agent-photo {
	max-width: 196px;
}

.agent_slide ul {
	padding: 10px;
}

.agent_slide li {
	line-height: 15px;
	margin-bottom: 10px;
}
.agent-name {
	font-weight: 700;
	font-size: 1.5rem;
}

.caroufredsel_wrapper .agent_designations {
	display: none;
}

.ui-helper-hidden-accessible {
	display: none;
}
footer {
	padding: 19px 0px;
	font-size: 1.1rem;
	line-height: 16px;
	overflow: hidden;
	width: 100%;
	min-width: 1120px;
}
footer nav {
	margin-bottom: 20px;
	overflow: hidden;
}
footer .menu-title {
	display: none;
}
footer .menu {
	float: none;
	margin-top: 0px;
}
footer .menu ul {
	margin-right: 0;
	float: none;
	width: 100%;
	z-index: 0;
}
footer .menu ul:last-child {
	margin-right: 0px;
}
footer .menu li {
	float: left;
	margin: 10px 60px 10px 0;
	width: 220px;
	padding: 0;
}
footer .menu li a {
	padding: 0;
}
footer .subdivisionlist {
	display: block;
	width: 100%;
	margin-bottom: 10px;
	overflow: hidden;
}
footer .subdivisionlist h1 {
	font-size: 2rem;
}
footer .subdivisionlist li {
	float: left;
	margin: 10px 60px 10px 0;
	width: 220px;
	padding: 0;
	list-style: none;
}
footer .subdivisionlist li a {
	padding: 0;
	font-size: 1.5rem;
	text-decoration: none;
}
.footercopy {
	float: left;
	width: 700px;
}
.board_footer,
.footer_copyright,
.footer_disclaimer {
	margin-bottom: 8px;
}
.brokeridxlink {
	float: right;
	text-align: center;
	margin-top: 44px;
}
aside .featured-prop-heading,
aside #sidesearch .search-title {
	margin-top: 0px;
	margin-bottom: 25px;
}
aside .refine_search,
aside #sidesearch {
	padding-bottom: 20px;
	border-bottom: 1px solid #ccc;
	margin-bottom: 20px;
}

.refine_search li {
	line-height: 18px;
}

#sidesearch input[type="text"],
#sidesearch select {
	width: 230px;
}
#content aside #sidesearch li.ss_price_to {
	float: left;
	width: 36%;
}
#content aside #sidesearch .ss_price_from {
	float: left;
	margin-right: 15px;
	width: 36%;
}
#sidesearch .ss_price_from input,
#sidesearch .ss_price_to input {
	width: 100%;
}
#sidesearch .ss_bed {
	clear: both;
}
.refine_search select,
.refine_search input[type="text"] {
	float: none;
	width: 230px;
}
.refine_search span.refine-price-from input[type="text"],
.refine_search span.refine-price-to input[type="text"] {
	width: 112px;
}
.refine_search span.refine-price-to {
	margin-left: 6px;
}
.refine_search .refine-price-from {
	display: inline-block;
	margin-bottom: 0;
}
.refine_search input,
aside #sidesearch input,
.refine_search select,
aside #sidesearch select,
.mtg_form input,
.cma_form_container input,
.cma_form_container select {
	margin-bottom: 10px;
}
.cma_form_container .cma_form:last-of-type label {
	width: 170px;
}
.cma_form_container input.form-check-input {
	margin-bottom: 0;
}
.featuredprop_title,
.search-title,
.subhead_agentlist,
.social-title,
.notice_title,
.detail-social-title,
.detail-features-title,
.refine_search h2 {
	font-size: 1.7rem;
	margin: 0px 0px 25px;
	text-transform: uppercase;
}

.subdivision-list-title,
.condominium-list-title,
.comment_title,
.agent_listings_header {
	font-size: 1.7rem;
	margin: 25px 0px 10px;
	text-transform: uppercase;
}

.subhead_agentlist {
	margin: 0px 0px 15px;
}
.refine_search .search-title {
	font-size: 1.7rem;
	margin: 0px 0px 25px;
	text-transform: uppercase;
}
#content aside .refine_search .refine_price select {
	padding: 7px 5px;
	width: 113px;
}

#content aside .refine_search .the_search {
	margin-left: 60px;
}
.blog {
	padding-bottom: 20px;
	margin-bottom: 30px;
}
.blog:first-child {
	padding-top: 20px;
}
.blog .social {
	margin: 25px 0 15px;
	overflow: hidden;
	text-align: left;
}
.blog .social h3 {
	margin-bottom: 5px;
}
.blog .social li {
	float: left;
	width: 32px;
	height: 38px;
	overflow: hidden;
	margin-right: 3px;
}

.blog-title {
	font-size: 1.8rem;
	margin-bottom: 10px;
}
.blog-body p {
	margin-bottom: 15px;
	line-height: 20px;
}
.blog-body img {
	width: 100%;
}
span.designation {
	display: inline;
}
/* Breadcrumb */
#breadcrumbs {
	border-top: 1px solid #282828;
	padding: 10px 0px;
	overflow: hidden;
}
#breadcrumbs .container {
	border-bottom: 1px solid #ccc;
	padding-bottom: 10px;
}
.breadcrumb li {
	float: left;
	margin-right: 10px;
	line-height: 22px;
	background: url(/templates/template_35/images/breadcrumbs-arrow.png) no-repeat
		left center;
	padding-left: 15px;
	list-style: none;
}
.breadcrumb li:first-child {
	background: none;
	padding-left: 0;
}
/* About */
.agent_image,
.agent_bio,
.agent_message {
	margin-bottom: 5px;
}
.agent_image img {
	margin-right: 20px;
}

.fplist.agentlist .agent_link {
	overflow: hidden;
	width: 100%;
	text-align: center;
	padding-bottom: 20px;
}

.fplist.agentlist .agent_designations,
.agentpage .agent_designations,
.contactform.the_contact_form .agent_designations {
	font-size: 1.5rem;
}

.contactform.the_contact_form .required_notice {
	display: block;
	font-size: 1.5rem;
}

.fplist.agentlist .agent_link a {
	display: inline-block;
	font-size: 1.5rem;
	line-height: 24px;
	margin-top: 5px;
	padding: 5px 15px;
	text-transform: uppercase;
	cursor: pointer;
	background: #282828;
	color: #fff;
	text-decoration: none;
}

.agentpage {
	padding-bottom: 20px;
}

.pagebody .agentpage img {
	float: left;
	margin-right: 20px;
}

.agentpage .agent_bio {
	margin-left: 0;
	float: none;
	display: block;
}

.contactform.the_contact_form div {
	font-size: 2rem;
}
/* mortgage calculator */
input[type="text"].form-control-plaintext {
	border: none;
	height: auto;
}

.mtg_output label {
	margin-top: 7px !important;
	width: auto !important;
}

/* Content */
.pagebody .page-title {
	margin-bottom: 15px;
	text-transform: uppercase;
	font-size: 2rem;
}

.pageholder .agent_image {
	margin-bottom: 15px;
	margin-left: 2px;
}

.pageholder .agent_office {
	overflow: hidden;
	font-size: 1.7rem;
	margin-bottom: 10px;
}

.pageholder .agent_bio,
.pageholder .agent_message {
	margin-left: 0px;
	display: block;
	margin-bottom: 7px;
	float: none;
}

.pageholder .agent_message {
	line-height: 18px;
}
/* pagination */
.pagination,
.sort_list,
.returnswitch {
	overflow: hidden;
	margin: 10px 0px;
}
.sort_list {
	margin-bottom: 10px;
}
.sort_list img {
	position: relative;
	top: 3px;
}
.pagination li,
.sort_list li,
.returnswitch li,
.sort_list li,
.returnswitch li {
	display: inline-block;
	margin: 5px;
}

#pagination_container span {
	display: inline-block;
}

#pagination_container form,
.results_returns {
	margin: 0 4px;
	display: inline-block;
	vertical-align: middle;
}

#pagination_container .page_select,
#pagination_container .results_returns select {
	width: 75px !important;
}
/* contact */
.contactform .alt_property_container {
	overflow: hidden;
	float: none;
	border: 1px solid #ccc;
}
.contactform > form ol li .contactform_thename {
	margin-left: 5px !important;
	margin-right: 5px !important;
}
.pagebody.brokers_list .agentlist {
	overflow: hidden;
}
.pagebody.brokers_list .agentlist li {
	width: 170px;
	margin: 0px 20px 30px 0px;
	text-align: center;
	list-style: none;
}
.pagebody.brokers_list .agentlist li img {
	max-width: 168px;
	margin-top: 5px;
}
.pagebody.brokers_list .agentlist li b,
.pagebody.brokers_list .agentlist li label {
	display: block;
	margin-top: 10px;
	font-weight: bold;
	text-transform: uppercase;
	word-wrap: break-word;
	line-height: 16px;
	margin-bottom: 10px;
}
.pagebody.brokers_list .agentlist li > a {
	display: block;
}
.pagebody.brokers_list .agentpage {
	margin-bottom: 20px;
	padding-bottom: 20px;
}
.pagebody.brokers_list .required_notice {
	font-size: 1.4rem;
	text-transform: none;
}
.searchitems h2,
.searchitems .notice_title {
	font-size: 1.5rem;
	line-height: 24px;
	text-transform: uppercase;
	margin-bottom: 5px;
	display: inline;
}
.searchitems ol {
	display: inline;
}
.searchitems li {
	margin-bottom: 5px;
	display: inline-block;
	margin-left: 10px;
}
.searchitems li strong {
	font-weight: 700;
}
.pricedrop,
.green,
.adult,
.virtualtour,
.foreclosure,
.shortsale,
.view_similar {
	font-size: 1rem;
}
.propholder:hover .green {
	background-image: url("/templates/common/flags/white_green_icon.png");
}
.propholder:hover .adult {
	background-image: url("/templates/common/flags/white_adult_icon.png");
}
.propholder:hover .virtualtour {
	background-image: url("/templates/common/flags/white_vtcamera.png");
}
.propholder:hover .shortsale {
	background-image: url("/templates/common/flags/white_shortsale_icon.png");
}
.propholder:hover .pricedrop {
	background-image: url("/templates/common/flags/white_reduced_icon.jpg");
}
.propholder:hover .foreclosure {
	background-image: url("/templates/common/flags/white_foreclosure_icon.png");
}
.propholder:hover .view_similar {
	background-image: url("/templates/common/flags/white_view_similar_icon.png");
}
.propholder h2 .like_off,
.propholder h2 .like_on {
	top: 13px;
	right: 10px;
	left: auto;
}
.propholder h2 .menu_log {
	position: absolute;
	top: 13px;
	left: 96%;
}
.fplist .propholder h2 .menu_log {
	left: 97%;
}
.testimonial_subtitle {
	font-size: 2rem;
	padding: 20px 0;
}
.featured-properties {
	box-sizing: border-box;
	clear: both;
	font-size: 1.3rem;
	line-height: 22px;
	margin-bottom: 25px;
	padding: 10px;
	width: 100%;
	overflow: hidden;
}
.featured-properties h2 {
	font-size: 1.8rem;
	line-height: 20px;
	padding: 10px 11px;
	margin-bottom: 20px;
	text-transform: uppercase;
}

.featuredprop .featuredprop {
	float: left;
	position: relative;
	width: 250px;
	height: 187px;
	overflow: hidden;
	margin: 0px 11px 22px;
	border: 1px solid #ccc;
	text-align: center;
}
.featured_listings_header {
	display: none;
}
aside .featuredprop .featuredprop {
	width: 250px;
	height: 190px;
	float: right;
}

.featuredprop img {
	max-height: 188px;
	max-width: 250px;
}
aside .featuredprop img {
	max-height: 190px;
	max-width: 250px;
}

.fpmorelink {
	width: 100%;
	overflow: hidden;
	text-align: center;
	line-height: 20px;
}

.fplist-container .fpmorelink {
	display: inline-block;
	width: auto;
}

.fpmorelink a {
	margin: 10px 0 0;
}

.featured_footer {
	position: absolute;
	z-index: 3;
	font-size: 1.5rem;
	text-align: left;
	line-height: 18px;
	padding: 6px 10px;
	top: 125px;
	left: 0px;
	text-transform: uppercase;
}
.featured_footer a {
	text-decoration: none;
}
.pagebody {
	width: 100%;
	clear: both;
	font-size: 1.3rem;
	line-height: 22px;
	padding: 10px;
	box-sizing: border-box;
	margin-bottom: 25px;
	overflow: hidden;
}
.address {
	font-size: 1.5rem;
	line-height: 24px;
	text-transform: uppercase;
}
.pricedrop,
.green,
.adult,
.virtualtour,
.foreclosure,
.shortsale,
.view_similar {
	float: right;
	line-height: 22px;
	text-transform: none;
	margin-right: 35px;
	padding-bottom: 0;
}
.propimage {
	float: left;
	text-align: center;
	padding-left: 10px;
	min-height: 260px;
}
.propimage img {
	width: 245px;
	height: 180px;
}
.contactbutton a {
	font-size: 1.5rem;
	line-height: 24px;
	text-transform: uppercase;
	padding: 5px 15px;
	display: inline-block;
	margin-top: 5px;
}
.prop {
	float: left;
	width: 330px;
	font-size: 1.2rem;
	line-height: 19px;
	margin-left: 20px;
	margin-bottom: 10px;
}

.prop.co_commn {
	width: 480px;
  float: left;
}

.co_commn_detail_wrap.single_col .prop.co_commn {
	margin-left: 0;
  width: 100%;
}
.article .prop {
	width: 230px;
}
.prop.col2 ol li,
.prop.col1 ol li {
	margin-bottom: 0px;
}

.propfooter {
	margin: 20px auto 0;
	text-align: center;
	width: 100%;
	font-size: 1.2rem;
	line-height: 19px;
	padding: 10px;
	overflow: hidden;
	box-sizing: border-box;
}
.propfooter b,
.propfooter laebl {
	width: auto !important;
}
.pagebody .propholder h2 {
	padding-bottom: 10px;
}
.search_results {
	margin-bottom: 30px;
	overflow: hidden;
	padding-bottom: 20px;
}
.pageholder .search_results {
	display: none;
}
.alt_property_container {
	width: 345px;
	float: left;
	position: relative;
	margin: 20px 9px;
	padding: 0;
}

.search_results .alt_description {
	top: 40px;
}

.search_results .alt_property_container {
	width: 373px;
	margin: 20px 25px 20px 0px;
}

.search_results .alt_property_container:nth-child(2n) {
	margin: 20px 0 20px 25px;
}

.search_results.full_layout .alt_property_container:nth-child(2n) {
	margin: 20px 0;
}

.alt_property {
	min-height: 415px;
}
.alt_title {
	position: absolute;
	top: 0%;
	padding: 10px;
	left: 0px;
	right: 0px;
	opacity: 0.8;
	font-weight: bold;
	text-align: left;
	margin: 0 !important;
}
.alt_description {
	position: absolute;
	top: 42px;
	padding: 15px;
	left: 0px;
	right: 0px;
	display: none;
	opacity: 0.9;
	min-height: 220px;
	box-sizing: border-box;
	margin: 0;
}
.fplist .alt_description {
	top: 40px;
}
.alt_propimage,
.alt_propimage img {
	width: 346px;
	height: 260px;
}
.search_results .alt_propimage,
.search_results .alt_propimage img {
	width: 374px;
}
.alt_propimage img {
	border: 1px solid #e3e3e3;
	box-sizing: border-box;
}
.alt_details {
	padding: 12px 5px;
	padding-left: 10px !important;
	width: auto;
}

.more_info_container {
	display: none;
}
.alt_col1 {
	position: absolute;
	top: 260px;
	left: 0px;
}
.alt_col2 {
	position: absolute;
	top: 260px;
	right: 0px;
	left: 158px;
}
.alt_col2 li,
.alt_col1 li {
	list-style: none;
	font-size: 1.3rem;
	padding: 0;
	margin-bottom: 0;
	line-height: 20px;
}
.alt_price {
	position: absolute;
	bottom: 8px;
	left: 80px;
	padding: 2px 25px;
}
.alt_contact {
	position: absolute;
	bottom: 12px;
	left: 14px;
	margin: 0 15px;
	text-indent: -999px;
}
.alt_contact li {
	list-style: outside none none;
	float: left;
	margin: 0;
	text-indent: -99999px;
}
.favorites_link a,
.question_link a {
	display: block;
	width: 20px;
	height: 20px;
}
.more-info-link {
	position: absolute;
	z-index: 10;
	display: block;
	float: right;
	font-size: 1.3rem;
	right: 15px;
	bottom: 11px;
}
.alt_virtualtour,
.alt_green,
.alt_adult,
.alt_shortsale,
.alt_pricedrop,
.alt_foreclosure,
.alt_view_similar {
	float: right;
	padding-left: 0;
}

.alt_virtualtour,
.alt_shortsale,
.alt_foreclosure,
.alt_view_similar {
	background: none;
}

.alt_virtualtour a,
.alt_green a,
.alt_adult a,
.alt_shortsale a,
.alt_pricedrop,
.alt_foreclosure a,
.alt_view_similar a {
	text-indent: -9999px;
	width: 20px;
	height: 20px;
	float: right;
	background-position: right center;
}
.alt_virtualtour a {
	background-image: url("/templates/common/flags/white_vtcamera.png");
}
.alt_adult {
	background-image: url("/templates/common/flags/white_adult_icon.png");
}
.alt_shortsale a {
	background-image: url("/templates/common/flags/white_shortsale_icon.png");
	background-size: 20px 20px;
}
.alt_view_similar a {
	background-image: url("/templates/common/flags/white_view_similar_icon.png");
	width: 24px;
}
.alt_pricedrop {
	float: right;
	background-image: url("/templates/common/flags/white_reduced_icon.jpg");
	background-color: transparent !important;
}
.alt_green a,
.alt_green {
	width: 21px;
	height: 21px;
}
.alt_foreclosure a {
	background-image: url("/templates/common/flags/white_foreclosure_icon.png");
	width: 20px;
	height: 20px;
	background-size: 20px 22px;
}

.pricedrop, .green, .adult, .virtualtour, .foreclosure, .shortsale,
.view_similar, .alt_foreclosure, .alt_virtualtour, .alt_shortsale,
.alt_adult, .alt_pricedrop, .alt_green, .alt_view_similar {
	margin: 0 5px !important;
}

.printcomment {
	display: none;
}

/* Tooltip */
.ui-tooltip {
	padding: 8px;
	position: absolute;
	z-index: 99999999;
	max-width: 300px;
}
.ui-tooltip {
	padding: 5px 10px;
	border-radius: 3px;
	text-transform: uppercase;
	font-size: 1.3rem;
	font-weight: bold;
}
.arrow {
	width: 70px;
	height: 16px;
	overflow: hidden;
	position: absolute;
	left: 50%;
	margin-left: -35px;
	bottom: -16px;
}
.arrow::after {
	content: "";
	position: absolute;
	left: 20px;
	top: -20px;
	width: 25px;
	height: 25px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.arrow.top {
	top: -16px;
	bottom: auto;
}
.arrow.top:after {
	bottom: -20px;
	top: auto;
}
.arrow.left {
	left: 20%;
}
.alt_title a.menu_log {
	bottom: 0px;
	position: absolute;
	top: 424px;
	left: 44px;
	font-size: 1.3rem;
	color: #fff;
}

.alt_property .like_on,
.alt_property .like_off {
	text-indent: -9999px;
	position: absolute;
	top: 425px;
	left: 32px;
	cursor: pointer;
}

.full_layout .alt_property .like_on,
.full_layout .alt_property .like_off {
	position: static;
	float: right;
}

.propholder .like_login,
.propholder .like_on,
.propholder .like_off {
	margin-left: 10px;
}

.form_container form {
	margin-bottom: 20px;
}

.customer_testimonial_submit.submit_button {
	position: static;
}
.customer_search_form li label {
	width: 140px !important;
	display: inline-block;
}
.customer_search_form li b,
.customer_search_form li laebl  {
	width: 165px !important;
}
#content .customer_search_form li div.searchprice input {
	margin: 0 5px 10px !important;
	width: 227px !important;
}
/* #content input[type="text"].profile_firstname,
#content input[type="text"].profile_lastname {
	margin-left: 10px;
	width: 200px !important;
} */
.account_settings input[type="text"],
.account_settings input[type="email"],
.account_settings input[type="phone"],
.account_settings input[type="password"],
.account_settings select {
	width: 240px;
}
#content .account_registration li input {
	margin-left: 0;
}
#content .account_registration li select {
	margin-left: 0;
	width: 240px;
}
#content input[type="radio"] {
	width: 5%;
}
input[type="submit"],
input[type="button"] {
	margin-left: 0;
}
#content li.search_private b,
#content li.search_private laebl  {
	width: 170px !important;
}
.form_container li b,
.form_container li label  {
	display: inline-block;
	width: 160px;
	text-align: left;
}
.form_container .account_settings {
	padding-bottom: 15px;
	margin-bottom: 20px;
}
.form_container .search_links .search_bttn {
	position: absolute;
	/*top: 80px;*/
	right: -5px;
}
#content .account_settings input[type="radio"] {
	width: auto;
}

#content .account_settings input[type="radio"] {
	margin: 0 5px;
}

#content .account_settings input[type="radio"]:first-of-type {
	margin-left: -5px;
}
.saved_istings_container .alt_propimage,
.saved_istings_container .alt_propimage img,
.alt_propimage .saved_istings_container img,
.saved_istings_container .alt_propimage img,
.alt_propimage .saved_istings_container img img {
	width: 345px;
	height: 230px;
}
.saved_istings_container .alt_title a.menu_log {
	top: 396px;
}
.saved_istings_container .alt_title .like_on,
.saved_istings_container .alt_title .like_off {
	text-indent: -9999px;
	position: absolute;
	left: 30px;
}
.saved_istings_container .alt_price {
	bottom: 9px;
	background: transparent url("/templates/template_35/bttns/price-tag.png")
		no-repeat scroll 0 5px;
}

.saved_istings_container .alt_col2,
.saved_istings_container .alt_col1 {
	top: 230px;
}
.saved_istings_container .more-info-link {
	bottom: 9px;
}
.fplist.agentlist li {
	float: none;
	width: auto;
	margin: 0;
	text-align: left;
	border: none;
}
.fplist.agentlist li a {
	min-height: auto;
}
.fplist.agentlist .alt_price {
	padding: 1px 25px;
}

.fplist.agentlist .alt_description {
	top: 34px;
	min-height: 196px;
}

  .testimonial-main .caroufredsel_wrapper {
	height: 100px !important;
  }


/* testimonial form*/
.testimonial_form {
	margin: 0;
}

.testimonial_form form li {
	list-style: outside none none;
	margin-bottom: 10px;
}

.agent-testimonial-title {
	font-size: 1.8rem;
	font-weight: bold;
	margin: 15px 0;
	text-transform: uppercase;
	text-align: center;
}

.testimonial {
	border: 1px solid #ccc;
	padding: 10px;
	box-sizing: border-box;
	margin: 0 20px 20px;
}

.testimonial .testimonial_title {
	float: left;
	font-size: 1.8rem;
	margin-bottom: 0px;
	padding: 5px;
	color: #fff;
	font-weight: normal;
}

.testimonial .testimonial_title a {
	color: #fff;
	text-decoration: none;
}

.testimonial .testimonial_body,
.testimonial .testimonial_agent {
	clear: both;
}

.testimonial .testimonial_date {
	background-color: rgba(0, 0, 0, 0.8);
	color: #fff;
	padding: 5px;
}

/* liked listing */
.user_liked_listing {
	position: fixed;
	width: 260px;
	max-height: 430px;
	left: -290px;
	z-index: 1;
}
.user_liked_listing .customer_likes {
	position: relative;
	padding: 10px;
	background: #fff;
	max-height: 430px;
	overflow-y: scroll;
	-ms-overflow-y: scroll;
	box-shadow: 2px 0px 5px 2px rgba(0, 0, 0, 0.2);
}
.user_liked_listing .customer_prop img {
	height: 143px;
}
.user_liked_listing .liked-list-title {
	margin: 10px 0px;
	font-size: 2.2rem;
}
.user_liked_listing .featured_footer {
	position: static;
	text-align: center;
	background: none;
}
.user_liked_listing .featured_footer a {
	float: none;
	padding-right: 0;
	margin-left: 0;
	display: inline-block;
	min-height: auto;
}
.user_liked_listing .featured_footer a::after,
.user_liked_listing .featured_footer a:hover:after {
	background: none;
}
.user_liked_listing .likepage_link {
	padding: 10px 0 20px;
	display: inline-block;
}
.user_liked_listing span {
	position: absolute;
	top: -20px;
	z-index: 10;
	right: -15px;
	display: block;
	cursor: pointer;
	background: #fff url("../images/carousel-arrow_black.png") no-repeat scroll
		center center;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	border: 1px solid #ccc;
	box-shadow: 3px 0px 9px 0px rgba(0, 0, 0, 0.2);
}
.btn_liked_listing {
	position: fixed;
	top: 50%;
	left: -107px;
	border: 1px solid #fff;
	height: 40px;
	line-height: 35px;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	width: 170px;
	text-align: center;
	cursor: pointer;
	font-size: 1.8rem;
	color: #fff;
	background: #000;
	margin-top: -85px;
}
.social-links {
	overflow: hidden;
}

.pagebody #login label {
	width: 140px;
	display: inline-block;
}

.pagebody #login input[type="text"],
.pagebody #login input[type="password"] {
	width: 240px;
}

.contact_agent_popup,
.schedule_showing_popup,
.detailform {
	display: none;
}
.propholder h2 {
	padding: 10px !important;
}
.contactform_instructions {
	margin-bottom: 25px;
}

/* agent contact form */
.contactform.the_contact_form {
	background: #fff;
	padding: 10px;
}

.contactform.the_contact_form h2 {
	text-transform: none;
	background: #000;
	color: #fff;
	padding: 10px;
}

.contactform.the_contact_form li:first-child {
	padding-top: 10px;
}

.contactform.the_contact_form .agent_designations {
	font-size: 1.5rem;
}
.agent-widget .widget-link.agent-widget-link span {
	text-decoration: underline;
}

.agent-widget .widget-link.agent-widget-link span:hover {
	text-decoration: none;
}
.contactform.the_contact_form input.thefirstname[type="text"] {
	width: 210px;
}

.pagebody .social_link {
	float: none;
}

.popupform a.fb_login:hover {
	text-decoration: none !important;
}

.social-links a.fb_login:hover {
	text-decoration: none !important;
}

/* detail page popups */
.information-popup h2.instructions,
.detailform h2.instructions {
	border-bottom: none;
}

.request_info {
	width: 250px;
	padding: 5px 0 5px 26px;
	border-radius: 3px;
	cursor: pointer;
	text-transform: uppercase;
	text-decoration: none;
	text-align: left;
	font-size: 1.2rem;
	font-weight: 600;
	min-height: 30px;
	box-sizing: border-box;
	line-height: 30px;
	margin-bottom: 30px;
}
.request_info:active,
.request_info:focus {
	outline: 0;
}

.contact_popup,
.schedule_showing,
.bidx_printbutton {
	display: block;
	margin-bottom: 10px;
	font-size: 1.3rem;
	font-weight: 600;
	cursor: pointer;
	width: 250px;
	border-radius: 3px;
	text-transform: uppercase;
	padding: 10px 0 10px 30px;
	box-sizing: border-box;
	line-height: 18px;
	text-decoration: none;
	outline: none;
}

.bidx_printbutton a {
	text-decoration: none;
	display: block;
}

.information-popup .radio-btn,
.ui-dialog .detailform b,
.ui-dialog .detailform laebl {
	color: inherit;
}
.detailform,
.contact_agent_popup,
.schedule_showing_popup {
	background: #f7f7f7;
	border: 1px solid #f7f7f7;
}

.detail_mini .contact_popup,
.detail_mini .schedule_showing {
	width: 248px;
}

.highlight.testimonial_body {
	padding: 0.5em 0 !important;
	margin: 1em 0;
}
.highlight.testimonial {
	line-height: 35px;
}

.popup-images.ui-dialog .ui-dialog-titlebar-close {
	right: 20px;
}

/* Notice */
.notice {
	color: #fff;
	background: #282828;
	padding: 10px;
	margin-bottom: 20px;
}

.notice .subhead {
	color: #fff;
	font-family: "Droid Sans", sans-serif;
	font-size: 1.3rem;
	font-weight: 700;
	padding-bottom: 5px;
}

/* Widget */
.widget {
	overflow: hidden;
	width: 49%;
	float: left;
	box-shadow: none;
	color: #282828;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 0px;
	position: relative;
}
.widget .widget-title {
	color: #fff;
	background-color: #000;
	text-transform: uppercase;
	font-family: "Droid Sans", sans-serif;
	font-size: 1.3rem;
	font-weight: 700;
}

.widget-add {
	right: 5px;
	bottom: 14px;
	top: auto;
}

.widget-add a {
	color: #000;
}

.widget-footer {
	background-color: #f7f7f7;
}

/* result page layouts */
.results_sort {
	text-align: right;
}

.sort_form {
	display: inline-block;
	vertical-align: top;
	margin-right: 10px;
}

.view_option {
	display: inline-block;
	border: 1px solid #d7d7d7;
	padding: 2px 5px 0;
}

.view1 {
	float: left;
	margin-right: 10px;
	cursor: pointer;
}

.view2 {
	float: left;
	cursor: pointer;
}

.view1:hover #minimalist_view,
.view2:hover #full_view {
	fill: #9e9e9e;
}

.view1:active #minimalist_view,
.view2:active #full_view,
.view1.active #minimalist_view,
.view2.active #full_view {
	fill: #3d8adc;
}

#pagination_container .results_returns select {
	width: 75px !important;
}

.alt_mlsid_bar {
	font-weight: 700;
	font-size: 1.8rem;
}

.detailComment {
	margin-bottom: 10px;
	line-height: 21px;
	list-style: none;
}

/* Result page full layout option */
.full_layout .pricedrop,
.full_layout .green,
.full_layout .adult,
.full_layout .virtualtour,
.full_layout .foreclosure,
.full_layout .shortsale,
.full_layout .alt_foreclosure,
.full_layout .alt_virtualtour,
.full_layout .alt_shortsale,
.full_layout .alt_adult,
.full_layout .alt_pricedrop,
.full_layout .alt_green {
	margin: 0;
}

.full_layout .alt_property_container {
	margin-bottom: 20px;
	overflow: hidden;
	border: 1px solid #d1d1d1;
	position: relative;
	width: 98%;
	float: none;
}

.full_layout .alt_propimage {
	float: left;
	width: 285px;
	height: auto;
}

.full_layout .alt_address a {
	color: #555;
}

.full_layout .alt_propimage img {
	width: 245px;
	margin: 20px;
	height: auto;
}

.full_layout .alt_property {
	border-bottom: none;
	min-height: 270px;
}

.full_layout .more-info-link {
	display: none;
}

.full_layout .alt_property_container .right_side {
	padding: 20px 0px;
	width: 479px;
	float: left;
}

.full_layout .alt_price {
	margin-bottom: 5px;
	float: left;
	margin-right: 30px;
	position: absolute;
	bottom: 66%;
	left: -50px;
	background: rgba(52, 52, 52, 0.75);
	color: #fff;
	width: 160px;
	font-weight: bold;
	padding: 5px 40px;
	display: block;
	text-align: center;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.full_layout .alt_title {
	border-bottom: 1px solid #d1d1d1;
	margin-bottom: 10px;
	background: none;
	position: static;
	padding: 0 0 5px 0;
}

.full_layout .alt_title a.menu_log {
	position: static;
	margin-right: 10px;
}

.full_layout .alt_property_container .description {
	border-bottom: 1px solid #d1d1d1;
	margin-bottom: 10px;
	font-size: 1.2rem;
	line-height: 16px;
	position: static;
	padding: 0 0 10px 0;
	opacity: 1;
	min-height: auto;
	display: block !important;
}

.alt_description:hover {
	display: block !important;
}

.full_layout .alt_contact {
	bottom: 30px;
	box-sizing: content-box;
	left: 20px;
	position: absolute;
	font-size: 1.3rem;
}

.full_layout .question_link a {
	background: none;
	width: auto;
}

.full_layout .alt_contact li {
	text-indent: 0;
	float: none;
}

.full_layout .alt_details.alt_col1 {
	width: 38%;
	float: left;
	font-size: 1.2rem;
	line-height: 16px;
	position: static;
}

.search_results.full_layout .alt_details.alt_col2 {
	width: 50%;
	position: static;
	float: right;
	font-size: 1.2rem;
	line-height: 16px;
	display: block;
}

.full_layout .alt_virtualtour a {
	background-image: url("/templates/common/flags/vtcamera.png");
}

.full_layout .alt_adult {
	background-image: url("/templates/common/flags/adult_icon.png");
}

.full_layout .alt_shortsale a {
	background-image: url("/templates/common/flags/shortsale_icon.png");
}

.full_layout .alt_pricedrop {
	background-image: url("/templates/common/flags/reduced_icon.png");
}

.full_layout .alt_foreclosure a {
	background-image: url("/templates/common/flags/foreclosure_icon.png");
}
.full_layout .alt_view_similar a {
	background-image: url("/templates/common/flags/view_similar_icon.png");
}

.alt_adult a {
	width: 16px;
	height: 16px;
}

/* Promotional Form */
.promo_form b,
.promo_form label {
	vertical-align: top;
	margin-top: 10px;
}

/* align button */
#content #commentform input.submitbtn,
#content .mtg_row.submit input[type="button"],
#content input.submitbtn {
	margin-left: 125px !important;
}

#content input.cma_button {
	margin-left: 180px !important;
}

.form_container .account_settings h2 {
	margin-bottom: 0;
}

/* create poll */
.membercount {
	display: block;
}
.poll-listing-container,
.poll_listing_container {
	float: left;
	margin: 20px 10px;
	border: none;
	padding: 0;
}
.pollcss .alt_property_container,
.poll_listing_container .alt_property_container,
.poll-listing-container .alt_property_container {
	border: none;
	padding: 0;
	float: none;
	margin: 20px 0;
}
.checkboxval {
	padding: 0px 5px;
	border: 1px solid #ccc;
}

.create_poll_listing_container .alt_col2 li,
.create_poll_listing_container .alt_col1 li,
.poll_listing_container .alt_col2 li,
.poll_listing_container .alt_col1 li {
	font-size: 1.1rem;
	padding: 0;
}
.create_poll_listing_container .alt_property_container:nth-child(2n) {
	margin: 20px 0px;
}

.create_poll_listing_container {
	float: left;
	margin: 20px 5px 20px 5px;
}

.polllist_row:hover {
	background: #3d8adc !important;
}
.polllist {
	border: none;
}

.poll-rating .rate2 {
	line-height: 28px;
}

.polllist td,
.polllist tr {
	border: none;
	border-bottom: 1px solid #000;
	text-align: left;
}

.detail-street-view,
.detail-map-view {
	height: calc(100% - 49px);
}

.poll-listings-container form {
	display: block;
	width: 100%;
	overflow: hidden;
}

/* detail popup */
.full_listing,
.view_this_home,
.showing_popup {
	float: left;
	font-size: 1.5rem;
	line-height: 24px;
	margin-top: 5px;
	padding: 5px 15px;
	text-transform: uppercase;
	text-decoration: none;
	cursor: pointer;
	background: #3d8adc;
	color: #fff;
	border-radius: 3px;
}

.top_buttons a:hover,
.showing_popup:hover,
.showing_popup:active,
.top_buttons a:active,
.showing_popup:focus,
.top_buttons a:focus,
.detail_pagination a:focus {
	color: #fff;
}

.view_this_home {
	float: right;
}

.showing_popup {
	float: none;
}

.social_icons .detail-social-title {
	color: #fff;
	margin-top: 40px;
}

.social_icons ul {
	overflow: hidden;
}

.social_icons li {
	float: none;
	display: inline-block;
	height: 38px;
	overflow: hidden;
}

.social_icons li:hover img {
	position: relative;
	top: -38px;
}

.social_icons img {
	height: auto;
}

/* Intro js */
.widget-add.introjs-showElement a {
	color: #000;
}

/*****************************************
 Poll Page Social icons
 *****************************************/
.share-url .social .social-title {
	text-align: center;
}
.share-url .social {
	float: left;
	width: 100%;
	box-sizing: border-box;
}
.share-url .social ul {
	padding: 5px;
}
.social li img {
	display: block;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 32px; /* Width of new image */
	height: 38px; /* Height of new image */
	padding-left: 32px; /* Equal to width of new image */
}
.social li:hover img {
	top: 0;
}
.social-custom-share {
	background: url(../bttns/generic.png) no-repeat;
}
.social li:hover .social-custom-share {
	background: url(../bttns/generic.png) no-repeat bottom;
}
.social-facebook {
	background: url(../bttns/facebook.png) no-repeat;
}
.social li:hover .social-facebook {
	background: url(../bttns/facebook.png) no-repeat bottom;
}
.social-digg {
	background: url(../bttns/digg.png) no-repeat;
}
.social li:hover .social-digg {
	background: url(../bttns/digg.png) no-repeat bottom;
}
.social-delicious {
	background: url(../bttns/delicious.png) no-repeat;
}
.social li:hover .social-delicious {
	background: url(../bttns/delicious.png) no-repeat bottom;
}
.social-google {
	background: url(../bttns/googlebookmark.png) no-repeat;
}
.social li:hover .social-google {
	background: url(../bttns/googlebookmark.png) no-repeat bottom;
}
.social-linkedin {
	background: url(../bttns/linkedin.png) no-repeat;
}
.social li:hover .social-linkedin {
	background: url(../bttns/linkedin.png) no-repeat bottom;
}
.social-reddit {
	background: url(../bttns/reddit.png) no-repeat;
}
.social li:hover .social-reddit {
	background: url(../bttns/reddit.png) no-repeat bottom;
}
.social-stumbled,
.social-stumbledupon {
	background: url(../bttns/stumbleupon.png) no-repeat;
}
.social li:hover .social-stumbled,
.social li:hover .social-stumbledupon {
	background: url(../bttns/stumbleupon.png) no-repeat bottom;
}
.social-twitter {
	background: url(../bttns/twitter.png) no-repeat;
}
.social li:hover .social-twitter {
	background: url(../bttns/twitter.png) no-repeat bottom;
}
.social-bluesky {
	background: url(../bttns/bluesky.png) no-repeat;
}
.social li:hover .social-bluesky {
	background: url(../bttns/bluesky.png) no-repeat bottom;
}
.social-linktree {
	background: url(../bttns/linktree.png) no-repeat;
}
.social li:hover .social-linktree {
	background: url(../bttns/linktree.png) no-repeat bottom;
}
.social-myspace {
	background: url(../bttns/myspace.png) no-repeat;
}
.social li:hover .social-myspace {
	background: url(../bttns/myspace.png) no-repeat bottom;
}
.social-copy {
	margin-top: 5px;
}
.social a:hover img.social-copy {
    top: 0;
}

/* Save Search */
.save-search a {
	margin: 0 0 20px;
	height: auto;
}

/* form styling */
.pagebody #login li {
	margin-bottom: 10px;
}

/* search results form */
.contact-form-login-container .social_link {
	float: none;
}

.contact-form-login-container .social_link a {
	float: none;
	margin: 0px auto;
}
/**********************************************
	CSS for Homepage Banner with Search Bar
***********************************************/
#homepage-banner {
	position: absolute;
	top: 0;
	z-index: 2;
	left: 0;
	right: 0;
	bottom: 0;
	height: 470px;
}
.homepage-banner-wrap .banner-heading {
	text-align: center;
	color: white;
}
.homepage-banner-wrap .banner-heading h1 {
	margin-top: 85px;
	font-size: 5rem;
	border-bottom: none;
	text-transform: none;
	padding: 0;
	margin-bottom: 0px;
	text-shadow: 5px 5px 10px #000000;
}
.homepage-banner-wrap .banner-heading p {
	font-size: 2.5rem;
	line-height: 40px;
	margin: 0px;
	text-shadow: 5px 5px 10px #000000;
}
.homepage-banner-wrap .banner-menu {
	text-align: center;
	color: white;
	max-width: 700px;
	margin: 40px auto 20px;
	position: relative;
	overflow: hidden;
}
.homepage-banner-wrap .banner-menu-items {
	float: left;
	width: 100%;
}
.homepage-banner-wrap .banner-menu-button {
	float: left;
	width: 27%;
	margin: 2px;
	text-align: center;
	font-size: 1.6rem;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 2px;
	color: #ffffff;
	padding: 15px 20px;
	cursor: pointer;
	border-bottom: 5px solid transparent;
	text-decoration: none;
}
.homepage-banner-wrap .banner-menu-button:hover {
	background-color: black;
	border-color: #f7eb41;
}
.homepage-banner-wrap .active {
	background-color: black;
	border-color: #f7eb41;
}
.homepage-banner-wrap .banner-searchbar {
	display: table;
	width: 100%;
	margin: 10px 0px;
	float: left;
}
.homepage-banner-wrap .banner-searchbar input[type="search"] {
	width: 70%;
	height: 45px;
	border-radius: 2px;
	padding: 2px 10px;
	background-color: #fff;
	border: 0;
}
.homepage-banner-wrap .banner-searchbar button {
	width: 29%;
	padding: 12px;
	color: black;
	border: none;
	font-size: 1.8rem;
	border-radius: 2px;
	background-color: #f7eb41;
	margin-left: 1px;
}
.banner-searchbar button:hover {
	background-color: #fbfb00;
}
.banner-testimonials .testimonial {
	float: left;
	background-color: rgba(0, 0, 0, 0.8);
}
.banner-testimonials .testimonial .testimonial_date {
	background: none;
}
.banner-testimonials .testimonial_body,
.banner-testimonials .testimonial_name {
	color: #fff;
}

.landing-page-wrap .landing-image-100 {
	width: 98%;
}
.landing-page-wrap .landing-image-50 {
	width: 48.2%;
	height: 250px;
}
.landing-page-wrap .landing-image-25 {
	width: 23.5%;
	height: 130px;
}
.landing-page-wrap .landing-page-form {
	width: 70%;
}

#upper-pagination_container {
	display: flex;
}

#lower-pagination_container {
	display: flex;
	justify-content: space-between;
}

.page_links_container {
	margin-top: 0;
}

.form-wrap .tabs li {
	width: auto;
}

/**********************************************
	Pocket Listings
***********************************************/
.widget-edit-form .input-radio div {
    width: 33%;
    min-height: auto;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding-bottom: 0;
}

.widget-edit-form .input-radio legend {
	font-weight: normal;
}

.customform-data-container select[multiple] {
	background: #fff !important;
}

.customform-data-container .documents-container {
    width: 97%;
}

.sidesearch-filter-list .home-page-list-group {
	display: none;
	position: absolute !important;
	width: 75%;
	height: 175px;
	margin-left: 0px;
	margin-top: -10px;
}
.sidesearch-filter-list .home-page-list-group .home-page-list-group-item {
	padding: 5px !important;
	font-size: 11px;
	width: 100% !important;
	position: relative !important;
	text-align: left !important;
	margin-bottom: 0px !important;
}

.sidesearch-filter-list .home-page-list-group .home-page-list-group-item:hover {
	background-color: #ddd;	
}

#concessions-compensations .notice{
	color:#000;
}